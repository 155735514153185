.page {
    width: 100%;
    /* height: calc(100vh - 3rem); */
    background-color: #222629;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.text {
    color: #ffffff;
    font-size: 3rem;
    font-weight: 500
}

.textEmphasized {
    color: #86c232;
}

@media (max-width: 700px) {

    .text {
        font-size: 2.5rem;
    }
}

@media (max-width: 550px) {

    .text {
        font-size: 2rem;
    }
}

@media (max-width: 450px) {

    .text {
        font-size: 1.6rem;
    }
}

@media (max-width: 375px) {

    .text {
        font-size: 1.3rem;
    }
}