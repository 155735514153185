.header {
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.5rem;
    background-color: #222629;
    box-shadow: 0px 10px 30px -10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    box-sizing: border-box;
}

.headerName {
    color: #86c232;
    font-weight: 500;
    font-size: 1.3rem;
}

.headerItems {
    width: max-content;
    height: 3.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0;
}

.navbar {
    display: flex;
    flex-direction: row;
}

.navbarClosed {
    display: flex;
    flex-direction: row;
}

.anchor {
    color: inherit;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    cursor: pointer;
}

.anchor:hover {
    color: #86c232;
}
                    
.headerItem {
    height: 100%;
    color: #cbced0;
    font-weight: 500;
    list-style: none;
    display: flex;
    align-items: center;
}

.resumeItem {
    margin-left: .5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    color: #86c232;
    font-weight: 500;
    text-align: center;
    border: 1px solid #86c232;
    border-radius: 3px;
    cursor: pointer;
    background-color: #222629;
}

.resumeItem:hover {
    background-color: #324629;
}

.backgroundBlur {
    visibility: hidden;
}

.noBackgroundBlur {
    visibility: hidden;
}

.resumeItemWrapper {
    display: flex;
    align-items: center;
}

.hamburgerWrapper {
    display: none;
    height: 30px;
    width: 30px;
    position: relative;
    cursor: pointer;
}

.hamburger {
    background-color: #86c232;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    transition: transform 0.3s ease;
}

.hamburger::before {
    background-color: #86c232;
    height: 2px;
    content: "";
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;

    transition: bottom 0.15s ease 0.3s;
}

.hamburger::after {
    background-color: #86c232;
    height: 2px;
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;

    transition: top 0.15s ease 0.3s,
                transform 0.3s ease;
}

.hamburgerActive {
    background-color: #86c232;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    transform: rotate(225deg);
    transition: transform 0.3s ease 0.15s;
}

.hamburgerActive::before {
    background-color: #86c232;
    height: 2px;
    content: "";
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;

    bottom: 0;
    transition: bottom 0.15s ease;
}

.hamburgerActive::after {
    background-color: #86c232;
    height: 2px;
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;

    top: 0;
    transform: rotate(90deg);
    transition: top 0.15s ease,
                transform 0.3s ease 0.15s;
}

@media (max-width: 768px) {

    .backgroundBlur {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        height: 100vh;
        width: 100vw;
        opacity: 1;
        visibility: visible;
        background-color: rgba(0,0,0,.6);

        transition: visibility .3s ease,
                    opacity .3s ease;
    }

    .noBackgroundBlur {
        opacity: 0;
        visibility: hidden;
    }

    .hamburgerWrapper {
        display: flex;
        z-index: 4000;
        height: 30px;
        width: 30px;
        position: relative;
    }

    .navbar {
        padding-top: 5rem;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: 0;
        top: 0;
        height: 100vh;
        width: 300px;
        background-color: #323639;

        transition: right .3s ease;
    }

    .navbarClosed {
        padding-top: 5rem;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: 0;
        top: 0;
        height: 100vh;
        width: 300px;
        background-color: #323639;

        right: -300px;
        transition: right .3s ease;
    }

    .headerItems {
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .headerItem {
        height: 3.5rem;
    }

    .resumeItem {
        margin-left: 0;
        margin-top: 1rem;
        background-color: #323639;
    }

    .resumeItem:hover {
        background-color: #425639;
    }
}

@media (max-width: 450px) {

    .navbar {
        width: 250px;
    }

    .navbarClosed {
        width: 250px;
    }

    .headerItem {
        height: 3rem;
    }

}

@media (max-width: 375px) {

    .navbar {
        width: 225px;
    }

    .navbarClosed {
        width: 225px;
    }

}