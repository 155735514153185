.page {
    width: 100%;
    background-color: #404448;
    display: flex;
    flex-direction: column;
    padding: 2rem 0 .5rem 0;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.footer {
    margin-top: 1.5rem;
    color: #E8E8E9;
}

.cardLinks {
    font-size: 1rem;
    margin: 1rem;
}

.anchor {
    padding: 10px;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    margin: 0 5px;
}

.anchor:hover {
    color: #86c232;
}

.title { 
    display: flex; 
    align-items: center; 
}

.titleLine{
    border: solid 1px #222629;
    width: 3rem;
    margin: 0 1rem;
    box-sizing: border-box;
}

.titleText{
    color: #86c232;
    font-size: 2rem;
    font-weight: 500;
}

.info {
    color: #E8E8E9;
    font-size: 1.2rem;
    margin-top: 2rem;
    max-width: 70rem;
    padding: 0 2rem;
    box-sizing: border-box;
}

.content { 
    display: flex;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    max-width: 70rem;
}

@media (max-width: 800px) {

    .content { 
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
    }
}

@media (max-width: 450px) {

    .titleText{
        font-size: 1.5rem;
    }

    .info {
        font-size: 1rem;
    }
}